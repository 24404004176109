<template>
	<b-modal
		v-if="!$store.getters['ui/isLoading']"
		v-model="isActive"
		class="modal-new-login"
		:can-cancel="false"
	>
		<div class="card">
			<div class="card-content">
				<p class="paragraph mb-4 text-info">
					Olá estudante!
				</p>

				<p class="paragraph mb-4 text-info">
					Agora, adotamos o login através das <strong>credenciais do Office</strong>. Para
					entrar, <strong>insira seu CPF</strong> e você será automaticamente direcionado para
					concluir o processo de autenticação (caso ainda não tenha realizado).
				</p>

				<p class="paragraph mb-5 text-info">
					Se você possui mais de uma matrícula na instituição, acesso o link (<strong>Tenho
					mais de uma matrícula</strong>) na tela inicial de login para continuar seu acesso.
				</p>

				<div class="columns pt-5 is-vcentered is-centered">
					<div class="column has-text-centered">
						<b-field>
							<b-checkbox
								v-model="dontShowAgain"
								type="is-primary"
							>
								Não mostrar novamente
							</b-checkbox>
						</b-field>
					</div>

					<div class="column has-text-centered">
						<b-button
							type="is-primary"
							rounded
							@click="close"
						>
							OK
						</b-button>
					</div>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
const COOKIE_NAME = 'portal-azure-login-modal';

export default {
	name: 'ModalNewLogin',

	data() {
		let isActive = this.$store.state.ie.authOffice;

		const dontShowAgain = document.cookie.split('; ')
			.find(row => row.startsWith(COOKIE_NAME + '='));

		if (dontShowAgain) {
			isActive = false;
		}

		const now = new Date();
		const maxExhibition = new Date(2024, 7, 15, 0, 0, 0, 0);

		if (now > maxExhibition) {
			isActive = false;
		}

		return {
			isActive,
			dontShowAgain,
		};
	},

	methods: {
		close() {
			this.isActive = false;

			if (this.dontShowAgain) {
				document.cookie = COOKIE_NAME + '=1';
			}
		},
	}
}
</script>

<style lang="scss" scoped>
@import '@styles/_variables';

::v-deep {
	.modal-content {
		max-width: 638px !important;
		width: calc(100% - 20px);

		.card-content {
			padding: 2rem;

			.text-info {
				font-size: 1.125rem
			}
		}
	}
}
</style>
